// MSAL configuration
import { PublicClientApplication } from "@azure/msal-browser";
import env from "../env";

const configuration = {
  auth: {
    clientId: env.AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${env.AZURE_TENANT_ID}`,
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
};

const pca = new PublicClientApplication({ ...configuration });

export const acquireAccessToken = async () => {
  const activeAccount = pca.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = pca.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    return null;
  }
  const request = {
    scopes: [`${env.AZURE_CLIENT_ID}/.default`],
    account: activeAccount || accounts[0],
  };

  const authResult = await pca.acquireTokenSilent(request);

  return authResult.accessToken;
};

export const isAuthenticated = () => {
  const accounts = pca.getAllAccounts();
  const activeAccount = pca.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  return !!activeAccount || accounts.length > 0;
};

export const logout = () => {
  return pca.logoutRedirect();
};

export default pca;
